import React, { useState, useContext } from "react";
import Footer from "../../../components/layout/Footer";
import HeaderAlt from "../../../components/layout/HeaderAlt";
import { FaLocationDot } from "react-icons/fa6";
import { AuthContext } from "../../../context/auth";
import { Link, useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../../api/axios";

const ChangePwd = () => {
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [oldPwd, setOldPwd] = useState(null);
  const [newPwd, setNewPwd] = useState(null);
  const [pwdConfirm, setPwdConfirm] = useState(null);

  const [error, setError] = useState("");

  const updatePassword = async () => {
    setError("");
    
    // Field validation
    if (!oldPwd || !newPwd || !pwdConfirm) {
      return setError("Ces champs sont obligatoires.");
    }

    // Password match validation
    if (newPwd !== pwdConfirm) {
      return setError("Les mots de passes ne sont pas identiques.");
    }

    setIsLoading(true);
    try {
      const response = await axiosPrivate.put("/auth/password", {
        oldPwd,
        newPwd
      });
      
      if (response.status === 200) {
        navigate("/profil");
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message === "invalid password"
        ? "Le mot de passe fourni est incorrect."
        : "Une erreur est survenue. Merci de réessayer";
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <HeaderAlt backgroundColor="white" />
      <div className="w-full  mt-[5vh] lg:mt-[10vh] flex flex-col items-center">
        {/* MAIN INFO BLOCK */}
        <div className="w-full lg:w-[70%] lg:min-h-64  flex justify-between px-4 py-8 lg:p-8">
          <div className="flex">
            <img
              src={userData.info.profilePicture}
              alt=""
              className="w-24 lg:w-48 aspect-square h-24 lg:h-48 object-cover rounded-full"
            />
            <div className="flex flex-col ml-3 py-3 lg:py-7 justify-between">
              <div className="flex flex-col">
                <h1 className="text-2xl line-clamp-1">
                  @{userData.info.username}
                </h1>
                <h1 className="text-xs mb-1 text-gray-500 ml-1">Novice</h1>
                <div className="flex items-center ml-1">
                  <FaLocationDot className="text-gray-700" />
                  <p className="font-bold text-sm text-gray-700">France</p>
                </div>
              </div>
              <h1 className="hidden lg:inline-block border border-gray-200 rounded-lg px-3 py-1">
                {userData.orders.length} articles achetés
              </h1>
            </div>
          </div>
          <div className="lg:border border-gray-200 rounded-lg lg:m-5 flex flex-col lg:flex-row h-fit p-2 lg:space-x-3">
            <div>
              <h1 className="font-bold">0</h1>
              <h1 className="text-xs lg:text-sm">Abonnés</h1>
            </div>
            <div>
              <h1 className="font-bold">0</h1>
              <h1 className="text-xs lg:text-sm">Abonnements</h1>
            </div>
          </div>
        </div>
        {/* FORM */}
        <div className="mt-10 lg:mt-0 w-full min-h-[70vh] flex flex-col items-center">
          <h2 className="text-2xl">Changer votre mot de passe.</h2>
          <div className="w-[90%] lg:w-1/4 px-2 mt-5">
            <p className="text-xs text-gray-600">Ancien Mot De Passe</p>
          </div>
          <input
            type="password"
            value={oldPwd}
            onChange={(e) => setOldPwd(e.target.value)}
            className="bg-black-200 w-[90%] lg:w-1/4 rounded-sm border-gray-200 shadow-sm focus:ring-black focus:border-black"
          />
          <span className="w-[10%] border border-gray-200 mt-5"></span>
          <div className="w-[90%] lg:w-1/4 px-2 mt-5">
            <p className="text-xs text-gray-600">Nouveau Mot De Passe</p>
          </div>
          <input
            type="password"
            value={newPwd}
            onChange={(e) => setNewPwd(e.target.value)}
            className="bg-black-200 w-[90%] lg:w-1/4 rounded-sm border-gray-200 shadow-sm focus:ring-black focus:border-black"
          />
          <div className="w-[90%] lg:w-1/4 px-2 mt-3">
            <p className="text-xs text-gray-600">Confirmation</p>
          </div>
          <input
            value={pwdConfirm}
            onChange={(e) => setPwdConfirm(e.target.value)}
            type="password"
            className="bg-black-200 w-[90%] lg:w-1/4 rounded-sm border-gray-200 shadow-sm focus:ring-black focus:border-black"
          />
          {error !== "" ? (
            <p className="mt-3 text-sm text-red-600">{error}</p>
          ) : null}
          {/* BUTTONS */}
          <div className="w-[90%] lg:w-1/4 mt-5 flex items-center justify-between">
            <Link
              to="/profil"
              className="flex items-center justify-center flex-1 bg-gray-100 border-gray-200 border mr-2 py-2 rounded-md hover:scale-105 duration-200"
            >
              <p className="text-sm">Retour</p>
            </Link>
            <button
              onClick={updatePassword}
              className="flex-1 ml-2 bg-black border border-black rounded-md py-2 hover:scale-105 duration-200"
            >
              <p className="text-sm text-white">
                {isLoading ? "Chargement" : "Confirmer"}
              </p>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePwd;
